// import React from "react";
// import { Col, Row } from "react-bootstrap";
// import Accordion from "react-bootstrap/Accordion";
// import Image from "next/image";
// import { toWebp } from "../../../utils/common";
// import styles from "./style.module.scss";

// const DeveloperMobile = ({ data }) => {
//   const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

//   return (
//     <>
//       {data?.table_rows &&
//         data?.table_rows.map((table, i) => {
//           return (
//             <React.Fragment key={i}>
//               {table?.points &&
//                 table?.points.map((point, ind) => {
//                   return (
//                     <React.Fragment key={`${ind}_j`}>
//                       {table.title === "Key Points" && (
//                         <Accordion
//                           defaultActiveKey={ind}
//                           className={`d-sm-block d-md-none ${styles["accordion"]}`}
//                         >
//                           <Row>
//                             <Col lg={6}>
//                               <Accordion.Item
//                                 eventKey={i}
//                                 className={`p-0 ${styles["accordion-item"]} ${styles["active"]}`}
//                               >
//                                 <Accordion.Header
//                                   className={`${styles["developer-table"]} ${styles["acordion-developer-table"]}`}
//                                 >
//                                   <ul
//                                     className={styles["developer-table-list"]}
//                                   >
//                                     <li
//                                       className={
//                                         ind % 2 === 0
//                                           ? styles["developer-list-blue"]
//                                           : styles["developer-list-green"]
//                                       }
//                                     >
//                                       <div
//                                         className={styles["developer-list-box"]}
//                                       >
//                                         <div
//                                           className={
//                                             styles["next-project-profile"]
//                                           }
//                                         >
//                                           <Image
//                                             src={
//                                               point?.image?.data?.attributes
//                                                 ?.url
//                                                 ? toWebp(
//                                                     `${baseUrl}${point?.image?.data?.attributes?.url}`
//                                                   )
//                                                 : null
//                                             }
//                                             alt={
//                                               point?.image?.data?.attributes
//                                                 ?.name || "project-img"
//                                             }
//                                             height={50}
//                                             width={50}
//                                           />
//                                         </div>
//                                         <span></span>
//                                       </div>
//                                       {point.data}
//                                     </li>
//                                   </ul>
//                                 </Accordion.Header>
//                                 <Accordion.Body
//                                   className={styles["accordion-body"]}
//                                 >
//                                   <div className={styles["developer-table"]}>
//                                     {data?.table_rows &&
//                                       data?.table_rows.map((table1, index) => {
//                                         return (
//                                           <React.Fragment key={`${index}_k`}>
//                                             {index !== 0 &&
//                                               table1?.points.map((p, indP) => {
//                                                 return (
//                                                   <React.Fragment
//                                                     key={`${indP}_l`}
//                                                   >
//                                                     {ind === indP && (
//                                                       <ul
//                                                         className={`${
//                                                           styles[
//                                                             "developer-inline-list"
//                                                           ]
//                                                         }  ${
//                                                           index % 2 !== 0
//                                                             ? styles[
//                                                                 "developer-bg-list"
//                                                               ]
//                                                             : ""
//                                                         }`}
//                                                       >
//                                                         <li>{table1.title}</li>
//                                                         {p.data !== null && (
//                                                           <li>{p.data}</li>
//                                                         )}

//                                                         {p.button !== null && (
//                                                           <li>
//                                                             <button
//                                                               onClick={() =>
//                                                                 p?.button &&
//                                                                 p?.button
//                                                                   ?.href !== ""
//                                                                   ? (window.location.href =
//                                                                       p?.button?.href)
//                                                                   : (window.location.href =
//                                                                       "#")
//                                                               }
//                                                               className={
//                                                                 indP % 2 === 0
//                                                                   ? "btn btn-primary"
//                                                                   : `btn btn-primary ${styles["btn-green"]}`
//                                                               }
//                                                             >
//                                                               {p.button.label}
//                                                             </button>
//                                                           </li>
//                                                         )}
//                                                       </ul>
//                                                     )}
//                                                   </React.Fragment>
//                                                 );
//                                               })}
//                                           </React.Fragment>
//                                         );
//                                       })}
//                                   </div>
//                                 </Accordion.Body>
//                               </Accordion.Item>
//                             </Col>
//                           </Row>
//                         </Accordion>
//                       )}
//                     </React.Fragment>
//                   );
//                 })}
//             </React.Fragment>
//           );
//         })}
//     </>
//   );
// };

// export default DeveloperMobile;


import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Image from "next/image";
import { toWebp } from "../../../utils/common";
import styles from "./style.module.scss";

const DeveloperMobile = ({ data }) => {
  const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

  // State to manage the currently active accordion key
  const [activeKeys, setActiveKeys] = useState({ "0_0": true }); // Default first component open

  const handleToggle = (key) => {
    setActiveKeys((prev) => ({
      ...prev,
      [key]: !prev[key], // Toggle the state for the specific key
    }));
  };

  return (
    <>
      {data?.table_rows &&
        data?.table_rows.map((table, i) => {
          return (
            <React.Fragment key={i}>
              {table?.points &&
                table?.points.map((point, ind) => {
                  const currentKey = `${i}_${ind}`;
                  return (
                    <React.Fragment key={`${ind}_j`}>
                      {table.title === "Key Points" && (
                        <Accordion
                          defaultActiveKey="0_0" // Open the first accordion by default
                          className={`d-sm-block d-md-none ${styles["accordion"]}`}
                        >
                          <Row>
                            <Col lg={6}>
                              <Accordion.Item
                                eventKey={currentKey}
                                className={`p-0 ${styles["accordion-item"]} ${styles["active"]}`}
                              >
                                <Accordion.Header
                                  onClick={() => handleToggle(currentKey)}
                                  className={`${styles["developer-table"]} ${styles["acordion-developer-table"]}`}
                                >
                                  <ul
                                    className={styles["developer-table-list"]}
                                  >
                                    <li
                                      className={
                                        ind % 2 === 0
                                          ? styles["developer-list-blue"]
                                          : styles["developer-list-green"]
                                      }
                                    >
                                      {activeKeys[currentKey]&& (
										<div
                                        className={styles["developer-list-box"]}
                                      >
                                        {/* Show image */}
                                        <div
                                          className={
                                            styles["next-project-profile"]
                                          }
                                        >
                                          <Image
                                            src={
                                              point?.image?.data?.attributes
                                                ?.url
                                                ? toWebp(
                                                    `${baseUrl}${point?.image?.data?.attributes?.url}`
                                                  )
                                                : null
                                            }
                                            alt={
                                              point?.image?.data?.attributes
                                                ?.name || "project-img"
                                            }
                                            height={50}
                                            width={50}
                                          />
                                        </div>
                                        <span></span>
                                      </div>
									  )}
                                      {point.data}
                                    </li>
                                  </ul>
                                  {/* Add "+" or "-" sign on the right */}
                                  <span
                                    className={styles["accordion-toggle-icon"]}
                                    style={{
                                      marginLeft: "auto",
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {activeKeys[currentKey] ? "-" : "+"}
                                  </span>
                                </Accordion.Header>
                                <Accordion.Body
                                  className={styles["accordion-body"]}
                                >
                                  {/* Render the full content of the accordion */}
                                  <div className={styles["developer-table"]}>
                                    {data?.table_rows &&
                                      data?.table_rows.map((table1, index) => {
                                        return (
                                          <React.Fragment key={`${index}_k`}>
                                            {index !== 0 &&
                                              table1?.points.map((p, indP) => {
                                                return (
                                                  <React.Fragment
                                                    key={`${indP}_l`}
                                                  >
                                                    {ind === indP && (
                                                      <ul
                                                        className={`${
                                                          styles[
                                                            "developer-inline-list"
                                                          ]
                                                        }  ${
                                                          index % 2 !== 0
                                                            ? styles[
                                                                "developer-bg-list"
                                                              ]
                                                            : ""
                                                        }`}
                                                      >
                                                        <li>{table1.title}</li>
                                                        {p.data !== null && (
                                                          <li>{p.data}</li>
                                                        )}

                                                        {p.button !== null && (
                                                          <li>
                                                            {/* <button
                                                              onClick={() =>
                                                                p?.button &&
                                                                p?.button
                                                                  ?.href !== ""
                                                                  ? (window.location.href =
                                                                      p?.button?.href)
                                                                  : (window.location.href =
                                                                      "#")
                                                              }
                                                              className={
                                                                indP % 2 === 0
                                                                  ? "btn btn-primary"
                                                                  : `btn btn-primary ${styles["btn-green"]}`
                                                              }
                                                            >
                                                              {p.button.label}
                                                            </button> */}
                                                          </li>
                                                        )}
                                                      </ul>
                                                    )}
                                                  </React.Fragment>
                                                );
                                              })}
                                          </React.Fragment>
                                        );
                                      })}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Col>
                          </Row>
                        </Accordion>
                      )}
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          );
        })}

      {/* Add a standalone button below */}
      {data?.button && (
        <div
          className={styles["developer-button-container"]}
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() =>
              data.button.href
                ? (window.location.href = data.button.href)
                : (window.location.href = "#")
            }
            className="btn btn-primary"
          >
            {data.button.label || "Default Button"}
          </button>
        </div>
      )}
    </>
  );
};

export default DeveloperMobile;
